import React, { useState } from 'react';
import './Navbar.css';
import { useNavigate } from 'react-router-dom';
import { Icons } from '../Common/Icons/Icons';
import i18n, { changeLanguage } from '../../i18n';
import { useTranslation } from 'react-i18next';
import x from '../../assets/images/x.svg';
import usaFlag from '../../assets/images/usa.png';
import spainFlag from '../../assets/images/spain.png';

interface NavbarProps {
  fullWidth?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ fullWidth }) => {
  const navigate = useNavigate();
  const [expandMenu, setExpandMenu] = useState(false);
  const [expandLanguages, setExpandLanguages] = useState(false);

  const { t: translate } = useTranslation();
  const t = (value: string) => translate(`t:components.navbar.${value}`);

  const handleLanguageChange = (newLanguage: string) => {
    changeLanguage(newLanguage);
    localStorage.setItem('selectedLanguage', newLanguage);
    window.location.reload();
  };

  const navbarStyle = {
    width: fullWidth ? '100%' : 'auto',
  };

  const navbarElement = {
    alignSelf: 'center',
    cursor: 'pointer',
  };

  return (
    <div className="mainDiv" style={navbarStyle}>
      <div className={'puntaLogo'}>
        <Icons.PuntaLogo />
        <div>
          PUNTA CANA
          <br />
          RENTAL YACHTS
        </div>
      </div>

      <div className={'centerNav'}>
        <div style={navbarElement} onClick={() => navigate('/')}>
          {t('home')}
        </div>
        <div style={navbarElement} onClick={() => navigate('/vessels')}>
          {t('vessels')}
        </div>
        <div style={navbarElement} onClick={() => navigate('/experiences')}>
          {t('experiences')}
        </div>
        <div style={navbarElement} onClick={() => navigate('/about')}>
          {t('aboutUs')}
        </div>
        <div style={navbarElement} onClick={() => navigate('/faq')}>
          {t('faq')}
        </div>
        <div style={navbarElement} onClick={() => navigate('/contact')}>
          {t('contact')}
        </div>
      </div>

      <div
        style={navbarElement}
        className={'langDiv'}
        onClick={() => setExpandLanguages(!expandLanguages)}
      >
        <p>{i18n.language.toUpperCase()}</p>
        <Icons.ArrowDown />
        {expandLanguages && (
          <div 
            className="dropdownLang"
            onClick={(e) => {
              e.stopPropagation();
              handleLanguageChange(i18n.language === 'en' ? 'es' : 'en');
              setExpandLanguages(false);
            }}
          >
            {i18n.language === 'en' ? (
              <div className={'languagesFlags'}>
                <img src={spainFlag} alt='Spanish flag' />
                <div>ES</div>
              </div>
            ) : (
              <div className={'languagesFlags'}>
                <img src={usaFlag} alt='USA flag' />
                <div>EN</div>
              </div>
            )}
          </div>
        )}
      </div>

      <div
        className="navbarMenuIcon"
        onClick={() => setExpandMenu(!expandMenu)}
      >
        <Icons.BurgerMenu width={25} height={19} />
      </div>

      {expandMenu && (
        <div className="navbarExpandedMenu">
          <div
            onClick={() => setExpandMenu(false)}
            style={{ position: 'absolute', right: 40 }}
          >
            <img src={x} alt='Close icon' />
          </div>
          <div style={navbarElement} onClick={() => navigate('/')}>
            {t('home')}
          </div>
          <div style={navbarElement} onClick={() => navigate('/vessels')}>
            {t('vessels')}
          </div>
          <div style={navbarElement} onClick={() => navigate('/experiences')}>
            {t('experiences')}
          </div>
          <div style={navbarElement} onClick={() => navigate('/about')}>
            {t('aboutUs')}
          </div>
          <div style={navbarElement} onClick={() => navigate('/faq')}>
            {t('faq')}
          </div>
          <div style={navbarElement} onClick={() => navigate('/contact')}>
            {t('contact')}
          </div>
          <div
            style={navbarElement}
            onClick={() => setExpandLanguages(!expandLanguages)}
          >
            <div className="langDivExpanded">
              <p>{i18n.language.toUpperCase()}</p>
              <Icons.ArrowDown />
            </div>
            {expandLanguages && (
              <div style={navbarElement} className="smallScreenLang">
                <div
                  className="langButton"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleLanguageChange(i18n.language === 'en' ? 'es' : 'en');
                    setExpandLanguages(false);
                    setExpandMenu(false);
                  }}
                >
                  {i18n.language === 'en' ? (
                    <div className={'languagesFlags'}>
                      <img src={spainFlag} alt='Spanish flag' />
                      <div>ES</div>
                    </div>
                  ) : (
                    <div className={'languagesFlags'}>
                      <img src={usaFlag} alt='USA flag' />
                      <div>EN</div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;