import React, {
  useRef,
  useState,
} from 'react';
import './ContactUs.css';
import Navbar from '../Home/Navbar';
import GoogleMapReact from 'google-map-react';
import Footer from '../Home/Footer';
import { Icons } from '../Common/Icons/Icons';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';

const ContactUs = () => {
  const { t: translate } = useTranslation();
  const t = (value: string) => translate(`t:components.contact.${value}`);
  const [thankYouText, setThankYouText] = useState('');

  const form = useRef<HTMLFormElement | null>(null);

  const sendEmail = (e: React.FormEvent) => {
    e.preventDefault();

    if (form.current) {
      emailjs
        .sendForm(
          'service_rghv6ys',
          'template_p2ohzpx',
          form.current,
          'lvUVivcyaCWionIPi',
        )
        .then((result) => {
          console.log(result.text);
          form.current = null;
          setThankYouText('Thank you for contacting us!');

        })
        .catch((error) => {
          console.log(error.text);
        });
    }
  };
  const divStyle = {
    width: '100%',
    backgroundColor: '#114859',
  };

  const defaultProps = {
    center: {
      lat: 18.39838876073877,
      lng: -68.90039368821442,
    },
    zoom: 15,
  };

  return (
    <div style={divStyle}>
      <Navbar />
      <div className="contactBody">
        <div className="contactHeader">
          <div className="abSeperator-hidden" />
          <div className="contactTitle">{t('heading')}</div>
          <div className={'contactSeperator'} />
        </div>
        <div className={'cardContent'}>
          <div className="contactCard">
            <form ref={form} onSubmit={sendEmail}>
              <div className="contactFormTitle">{t('formTitle')}</div>
              <div className="contactFormTitle">{thankYouText}</div>
              <div className="contactFormCol">
                <label>{t('name')}</label>
                <div className="contactInput">
                  <input
                    type="text"
                    name="name"
                    style={{
                      outline: 'none',
                      background: 'transparent',
                      border: 0,
                      width: '100%',
                      color: 'white',
                    }}
                  />
                </div>
              </div>
              <div className="contactFormCol">
                <label>{t('email')}</label>
                <div className="contactInput">
                  <input
                    type="email"
                    name="email"
                    style={{
                      outline: 'none',
                      background: 'transparent',
                      border: 0,
                      width: '100%',
                      color: 'white',
                    }}
                  />
                </div>
              </div>
              <div className="contactFormCol">
                <label>{t('message')}</label>
                <div className="contactInput">
                  <textarea
                    name="message"
                    style={{
                      outline: 'none',
                      background: 'transparent',
                      border: 0,
                      height: '150px',
                      resize: 'none',
                      width: '100%',
                      color: 'white',
                    }}
                  />
                </div>
              </div>
              <div className="contactSendButtonSection">
                <button
                  className="contactSendButton"
                  type="submit"
                  aria-label="Submit"
                >
                  {t('sendBttn')}
                </button>
              </div>
            </form>
          </div>
          <div className="contactCard2">
            <div
              style={{
                height: '350px',
                width: '100%',
                borderRadius: '25px',
                overflow: 'hidden',
              }}
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyC9UN05IzAN3tr1j-839aI57XCDMjUiha8',
                }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
              />
            </div>
            <div className="contactFormTitle">Casa de campo - La Romana</div>
          </div>
        </div>
        <div className="contactInfoSection">
          <div className="contactRow">
            <a
              href="https://www.google.com/maps?ll=18.397224,-68.899995&z=16&t=m&hl=en-US&gl=US&mapclient=apiv3"
              className="contactLink"
              target="_blank"
              rel="noreferrer"
              aria-label="Location link"
            >
              <Icons.GoldLocationIcon width={20} height={26} />
            </a>
            <div>
              Casa de campo - La Romana
            </div>
          </div>
          <div className="contactRow">
            <div className="contactSocialMedia">
              <a
                href="https://www.instagram.com/puntacanarentalyachts"
                className="contactLink"
                target="_blank"
                rel="noreferrer"
                aria-label="Instagram icon"
              >
                <Icons.GoldInstagramIcon width={25} height={25} />
              </a>
              <a
                href="https://www.threads.net/@puntacanarentalyachts"
                className="contactLink"
                target="_blank"
                rel="noreferrer"
                aria-label="Threads icon"
              >
                <Icons.GoldAtIcon width={21} height={24} />
              </a>
              <a
                href="https://www.tiktok.com/@puntacanarentalyachts"
                className="contactLink"
                target="_blank"
                rel="noreferrer"
                aria-label="TikTok icon"
              >
                <Icons.GoldTikTokIcon width={21} height={25} />
              </a>
              <a
                href="https://www.youtube.com/@PuntaCanaRentalYachts"
                className="contactLink"
                target="_blank"
                rel="noreferrer"
                aria-label="Youtube icon"
              >
                <Icons.GoldYoutubeIcon width={31} height={23} />
              </a>
              <a
                href="https://www.facebook.com/puntacanarentalyachts"
                className="contactLink"
                target="_blank"
                rel="noreferrer"
                aria-label="Facebook icon"
              >
                <Icons.GoldFacebookIcon width={24} height={24} />
              </a>
            </div>
            <div>@puntacanarentalyachts</div>
          </div>
          <div className="contactRow">
            <div className="contactSocialMedia">
              <a
                href="tel:+18494904364"
                className="contactLink"
                target="_blank"
                rel="noreferrer"
                aria-label="Phone icon"
              >
                <Icons.GoldPhoneIcon width={27} height={27} />
              </a>
              <a
                href={`whatsapp://send?phone=+18494904364`}
                className="contactLink"
                target="_blank"
                rel="noreferrer"
                aria-label="Whatsapp icon"
              >
                <Icons.GoldWhatsappIcon width={26} height={26} />
              </a>
            </div>
            <div>+1 849 490 4364</div>
          </div>
          <div className="contactRow">
            <a
              href="/"
              className="contactLink"
              target="_blank"
              rel="noreferrer"
              aria-label="Mail icon"
            >
              <Icons.GoldMailIcon width={25} height={20} />
            </a>
            <div>info@puntacanarentalyachts.com</div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
